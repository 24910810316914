import React, { useEffect, useState } from 'react';
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import parse from "html-react-parser";
import { cookieService, userService } from '../../_services';
import { toast } from 'react-toastify';
import ToastHeader from "../../components/toastHeader";
import { withRouter } from 'react-router-dom';
import { api } from '../../middleware/api';
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import Logout from '../../components/logout';

const Privacy = (props) => {
    const [content, setContent] = useState('');
    const [userData, setUserData] = useState<any>()
    const [show, setShow] = useState(false);
    useEffect(() => {
        setUserData(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user))
    }, [])
    useEffect(() => {
        userService.legalContent()
            .then((res) => {
                res.filter(i => i.contentType === "PP").map((item) => {
                    let parsedContent: any = parse(item.content);
                    //props.history.push('/signup');
                    return setContent(parsedContent);

                });
            })
            .catch(function (error) {
                ToastHeader(error);
            });
    }, []);

    const user = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            user: {
                isPrivacyPolicyAccepted: true
            }
        }
        userService.userProfile(data)
            .then((res) => {
                // console.log('Accepted', res)
            })
            .then(() =>
                userService.getUserData()
                    .then(res => {
                        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                    }))
            .then(() => {
                window.location.reload()
                // props.history.push(STRING_CONSTANTS.navigationString.dashboard.home)
            })
            .catch(function (error) {
                ToastHeader(error);
            });
    }

    // console.log(user)
    return (
        <section className="main-signup-wrap blue-bg remove-bg-top-round">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2 col-12">
                        <div className="left-wrap">
                            <div className="logo-wrap">
                                {!userData?.user?.id ? <img alt="" src={UtilityFunctions.getImageURL() + "logo_White.svg"} onClick={() => props.history.push('/')} /> :
                                    <img alt="" style={{ cursor: "default" }} src={UtilityFunctions.getImageURL() + "logo_White.svg"} />}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8 col-12 text-center">
                        <div className='my-4 my-sm-0 text-white d-flex justify-content-between align-items-center legalTitle'>
                            {/* IF USER IS LOGGED OUT  */}
                            {!userData?.user?.id ?
                                <span style={{ cursor: "pointer" }}>
                                    <i className="fa fa-angle-left" aria-hidden="true" onClick={() => props.history.push(STRING_CONSTANTS.navigationString.signup, { step: 1, isGuest: props?.location?.state?.isGuest, userType: props?.history?.location?.state?.userType, account_exists: props?.location?.state?.account_exists })}>
                                    </i>
                                </span>
                                :
                                // IF USER IS LOGGED IN AND ACCEPTED PRIVACY POLICY 
                                (userData?.user?.id && userData?.user?.isPrivacyPolicyAccepted) ?
                                    <span style={{ cursor: "pointer" }}>
                                        <i className="fa fa-angle-left" aria-hidden="true" onClick={() => props.history.goBack() ? props.history.goBack() : props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings)}>
                                        </i>
                                    </span>
                                    :
                                    // IF USER IS LOGGED IN AND NOT ACCEPTED PRIVACY POLICY 
                                    <span style={{ cursor: "pointer" }}>
                                        <i
                                            className="text-white d-flex justify-content-between"
                                            aria-hidden="true"
                                            style={{ position: "fixed" }}
                                            onClick={() => setShow(true)}
                                        >
                                            <img src={UtilityFunctions.getImageURL() + "cross-icon-white.svg"} width={'22px'} height={'22px'} alt="" />
                                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z" fill="white" />
                                        </svg> */}
                                        </i>
                                    </span>

                            }
                            <p className='m-0 w-100'>Privacy Policy</p>
                            <span></span>
                        </div>
                        <div className='terms-shadow2'>
                            <div className='legal-content'>
                                <div className='scroll-item'>
                                    {content}
                                </div>
                            </div>
                        </div>
                        {userData?.user?.id && !userData?.user?.isPrivacyPolicyAccepted && <button className='btn-cta terms-and-privacy' onClick={(e) => { handleSubmit(e) }}>Accept</button>}
                    </div>
                    <div className="col-sm-2 col-12"> </div>
                </div>
            </div>
            <Logout
                show={show}
                handleClose={() => setShow(false)}
            />
        </section>
    )
}
export default withRouter(Privacy);
