import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { STRING_CONSTANTS } from './utils/constants/stringConstants';
import { UtilityFunctions } from './utils/UtilityFunctions';
export default function Test() {
  return <>
    <section className={`main-signup-wrap`}>
      <div className="row">
        <div className="col-md-3 col-lg-4 col-xl-4 col-12">
          <div className="left-wrap">
            <div className="logo-wrap">
              <img
                alt=""
                src={UtilityFunctions.getImageURL() + "logo_White.svg"}
              // onClick={() => props.history.push(onBoarding)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4 col-12">
          <div className="middle-wrap">
            <div className='top-divde-spa'>
              <span>
                <i
                  className="fa fa-angle-left text-white d-flex justify-content-between"
                  aria-hidden="true"
                // onClick={() => props.history.push(onBoarding)}
                ></i>
              </span>
              <div className="step-content signup-mar">
                <button type='button' disabled>{STRING_CONSTANTS.signupPages.signup.sign_up}</button>
                <p>Are you looking to hire or<br /> looking to work?</p>
                <div className="step-text">
                  Step 1/8
                </div>
              </div>
            </div>
            <div className="form-wrap">
              <div className='custom-grid-setup d-flex justify-content-between mx-auto'>
                <div className='radio-button-card-set w-100'>
                  <input type="radio" name="select_job" id="business_owner" checked />
                  <label htmlFor="business_owner">
                    <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans.svg"} alt="" />
                    <p className='mb-0'>I’m a <b>Business Owner</b></p>
                  </label>
                </div>
                <div className='radio-button-card-set w-100'>
                  <input type="radio" name="select_job" id="employee" />
                  <label htmlFor="employee">
                    <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans.svg"} alt="" />
                    <p className='mb-0'>I’m an <b>Employee</b></p>
                  </label>
                </div>
              </div>
            </div>
            <div className="btn-maccount mobile-btn d-block d-md-none">
              <button type='button' >
                {STRING_CONSTANTS.signupPages.signup.account_exists}
              </button>
            </div>
            <div className="next-wrap">

              <button className="cricle-next" >
                <img alt="" src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"} />
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-lg-4 col-xl-4 col-12 d-none d-md-block">
          <div className="right-wrap">
            <div className="btn-maccount">
              <button>
                {STRING_CONSTANTS.signupPages.signup.account_exists}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}
// export default function Test() {
//   const [messages, setMessages] = useState([]);
//   const [input, setInput] = useState("");
//   const [newMessages, setNewMessages] = useState();

//   function getTimestamp(time) {
//     // Convert the time to a Date object
//     time = new Date(time);

//     // Get the current time
//     const currentTime = new Date();

//     // Calculate the difference between the current time and the given time
//     // @ts-ignore
//     const timeDifference = currentTime - time;

//     // If the time difference is less than one day, return the time in the format "HH:MM AM/PM"
//     if (timeDifference < 86400000) {
//       return time.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
//     }

//     // If the time difference is less than one year, return the time in the format "MMM DD, HH:MM"
//     if (timeDifference < 31536000000) {
//       return time.toLocaleDateString("en-US", { month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true });
//     }

//     // Otherwise, return the full date and time
//     return time.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true });
//   }

//   // Example usage
//   const timestamp = getTimestamp(1609459200);
//   // console.log(timestamp);  // Output: "Sep 24, 2021 9:00 AM"

//   // Define a function to update the timestamps
//   // This code converts the given timestamp (which is in Unix time) to a Date object, and then calculates the difference between t
//   function updateTimestamps() {
//     // Calculate the elapsed time for each message
//     const updatedMessages = messages.map((message) => {
//       // @ts-ignore
//       const elapsedTime = (new Date() - new Date(message.timestamp)) / 1000; // elapsed time in seconds
//       return {
//         ...message,
//         elapsedTime: elapsedTime
//       };
//     });

//     // Update the messages array in state with the new timestamps
//     setMessages(updatedMessages);
//   }

//   let diff
//   // Call the update function at regular intervals using setInterval
//   useEffect(() => {
//     const interval = setInterval(updateTimestamps, 60000);
//     return () => clearInterval(interval);
//   });

//   // Handle the send button click
//   function handleSendClick() {
//     // Add a new message to the messages array with the text from the input field and the current timestamp
//     setMessages([
//       ...messages,
//       { text: input, timestamp: new Date().toISOString() }
//     ]);

//     // Clear the input field
//     setInput("");
//   }

//   useEffect(() => {
//     for (let i = 0; i < messages.length; i++) {
//       var duration = moment.duration(moment(moment[i]?.timestamp).diff(moment(moment[i + 1]?.timestamp)));
//       var seconds = duration.asSeconds();
//       let newArr = [...messages];
//     }
//   }, [messages])

//   var duration = moment.duration(moment("2023-01-01T15:19:55.187Z").diff(moment("2023-01-01T15:19:52.067Z")));
//   var seconds = duration.asSeconds();

//   // moment("sometimestamp").fromNow()
//   // moment("sometimestamp").format("dddd DD-MMM-YY")

//   // Render the chat interface
//   return (
//     <div>
//       {messages.map((message) => (
//         <div key={message.timestamp}>
//           <p>{message.text}</p>
//           <p>{moment(message.timestamp).fromNow()}</p>
//         </div>
//       ))}
//       <form onSubmit={(e) => {
//         e.preventDefault();
//         handleSendClick();
//       }}><input value={input} onChange={(e) => setInput(e.target.value)} />
//         <button type="button" onClick={handleSendClick}>Send</button></form>
//     </div>
//   );
// }