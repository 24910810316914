/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import onboardingScreenData from "./collections";
import OnboardingScreen from "./OnboardingScreen";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import "./onboarding.css";
import "swiper/css";
import "swiper/css/pagination";
import { Fragment } from "react";
import { userData } from '../../utils/UserData'

const Onboarding = (props: any) => {

  const [activeIndex, setactiveIndex] = useState(props?.history?.location?.state?.activeIndex || 0)

  useEffect(() => {
    userData(props, '')
  }, [])

  useEffect(() => {
    const urlString = window?.location?.href;
    const data = urlString?.split("?");
    if (data.length > 1) {
      const promoData = data[1];
      const dataArray = promoData?.split("&");
      const promoCodeUrl = dataArray[1]?.split("=")[1];
      const productIdUrl = dataArray[2]?.split("=")[1];
      if (promoCodeUrl && productIdUrl) {
        localStorage.setItem('allPromoData', JSON.stringify(promoData));
      }
    }
  }, []);

  const onboardingScreen = onboardingScreenData.map((item, index) => {
    return (
      <Fragment key={index}>
        <SwiperSlide key={index}>
          <OnboardingScreen
            index={index}
            logo={item.primaryLogo}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            indexOfLastSlide={onboardingScreenData.length - 1}
          />
        </SwiperSlide>
        <button>
          {STRING_CONSTANTS.onboardingScreen.buttons.account_exists}
        </button>
        <button>{STRING_CONSTANTS.onboardingScreen.buttons.next_screen}</button>
        <button>{STRING_CONSTANTS.onboardingScreen.buttons.skip_all}</button>
      </Fragment>
    );
  });
  return (
    <Swiper
      pagination={{
        type: "progressbar",
      }}
      modules={[Pagination]}
      onSlideChange={({ activeIndex }) => setactiveIndex(activeIndex)}
      initialSlide={activeIndex}
    >
      {onboardingScreen}
    </Swiper>
  );
};

export default Onboarding;
