import { cookieService } from "../_services";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { Data } from "../_services/collections";

export const userData = (props, data) => {
  let userCookieData = cookieService.getCookie(
    STRING_CONSTANTS.cookieStrings.user
  ) as Data;
  userCookieData = userCookieData === null ? data.user : userCookieData;
  let d = userCookieData?.user?.sendOffers;
  let isProfileCompleted = userCookieData?.user?.isProfileCompleted;
  if (userCookieData) {
    if (d == null) props.history.push(STRING_CONSTANTS.navigationString.signup);
    if (
      window.location.pathname ===
      STRING_CONSTANTS.navigationString.dashboard.job_creation
    ) {
      props.history.push(
        STRING_CONSTANTS.navigationString.dashboard.job_creation
      );
    } else if (isProfileCompleted) {
      props.history.push(STRING_CONSTANTS.navigationString.dashboard.home);
    }
    // else if (d != null)
    //   props.history.push(STRING_CONSTANTS.navigationString.profile_completion);
    // console.log("6. last");
  }
};
