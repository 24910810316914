import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { api } from "../../middleware/api";

const EmployerForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    mobileNumber: '',
    industry: '',
    scenario: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [verificationCodeSubmitted, setVerificationCodeSubmitted] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData(prevState => ({
      ...prevState,
      mobileNumber: value
    }));
  };

  const validateForm = () => {
    if (!formData.firstName.trim()) return "First Name is required";
    if (!formData.companyName.trim()) return "Company Name is required";
    // if (!formData.email.trim()) return "Email is required";
    // if (!/\S+@\S+\.\S+/.test(formData.email)) return "Email is invalid";
    if (!formData.mobileNumber) return "Mobile Number is required";
    // if (!formData.industry) return "Industry is required";
    // if (!formData.scenario) return "Scenario is required";
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    // API call to submit the form data and send verification code
    api.post("ar-verify/", formData)
    .then((res) => {
      if (res?.data?.ok) {
        setFormSubmitted(true);
        setError('');
      } else {
        console.log(res)
        throw new Error('Form submission failed');
      }
    }).catch((err) => {
      setError(err.message);
    });
  };

  const handleVerifyAndStart = async () => {
    if (!verificationCode.trim()) {
      setError('Please enter the verification code');
      return;
    }

    // API call to verify the code and start the accounts receivable call
    api.post("ar-start/", { code: verificationCode })
    .then((res) => {
      console.log("RES IS", res)
      if (res?.data?.ok) {
        console.log("MADE IT HERE???")
        setVerificationCodeSubmitted(true);
        setError('');
      } else {
        throw new Error('Verification failed or call could not be started');
      }
    }).catch((err) => {
      setError(err.message);
    });
  };

  const initiateSignup = () => {
    window.location.href = '/signup';
  }

  const industries = [
    'Technology', 'Healthcare', 'Finance', 'Education', 'Retail',
    'Manufacturing', 'Construction', 'Hospitality', 'Other'
  ];

  const scenarios = [
    'Invoice owing 30 days for $4000',
    'Late payment on $10,000 contract',
    'Unpaid services worth $2500',
    'Overdue account of $7500',
    'Outstanding balance of $5000 for 45 days'
  ];

  if (formSubmitted && !verificationCodeSubmitted) {
    return (
      <div className="verification-section">
        <h2>Enter Verification Code</h2>
        <p>A verification code has been sent to your mobile number. Please enter it below to initiate the call.</p>
        <input
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Enter verification code"
          className="form-control mb-3"
        />
        {error && <div className="alert alert-danger">{error}</div>}
        <button onClick={handleVerifyAndStart} className="border-0 px-4 py-2 primaryButton">
          Start Accounts Receivable Call
        </button>
      </div>
    );
  }

  if (verificationCodeSubmitted) {
    return (
      <div className="verification-section">
        <h2>Call Initiated</h2>
        <p>Our AI Agent will now call you to give a demonstration of the accounts receivable system.</p>
        <p>Once you try it out, the next step is to sign up to the system and connect your accounting system, e.g. Xero</p>
        <button onClick={() => initiateSignup()} className="border-0 px-4 py-2 primaryButton">
          Connect now
        </button>
      </div>
    );
  }

  return (
    <div className="employer-section">
      <h1 className="main-heading">Try Before You Buy</h1>
      <form onSubmit={handleSubmit} className="employer-form">
        <div className="form-group">
          <label htmlFor="firstName">Your First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="firstName">Your Company Name</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        {/* <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div> */}

        <div className="form-group">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <PhoneInput
            country={'au'}
            value={formData.mobileNumber}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'mobileNumber',
              required: true,
            }}
          />
        </div>
{/* 
        <div className="form-group">
          <label htmlFor="industry">Industry</label>
          <select
            id="industry"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            className="form-control"
            required
          >
            <option value="">Select an industry</option>
            {industries.map((industry, index) => (
              <option key={index} value={industry}>{industry}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="scenario">Scenario</label>
          <select
            id="scenario"
            name="scenario"
            value={formData.scenario}
            onChange={handleChange}
            className="form-control"
            required
          >
            <option value="">Select a scenario</option>
            {scenarios.map((scenario, index) => (
              <option key={index} value={scenario}>{scenario}</option>
            ))}
          </select>
        </div> */}

        {error && <div className="alert alert-danger">{error}</div>}

        <button type="submit" className="border-0 px-4 py-2 primaryButton">
          Submit
        </button>
      </form>
    </div>
  );
};

export default EmployerForm;
