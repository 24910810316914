const locatingFns = {
  // API key
  apiKey: "AIzaSyDrHSgDgCZBq6EHrXba6JHSEhn5rv8tDIE",

  // Map API
  mapApiJs: "https://maps.googleapis.com/maps/api/js",

  // Geo Code JSON URL
  geocodeJson: "https://maps.googleapis.com/maps/api/geocode/json",

  // load google map api js
  loadAsyncScript: (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  },

  restrictionObj: {
    types: ["address"],
    componentRestrictions: { country: "au" },
  },

  parseGoogleAddressComponents: (addressComponents: any) => {
    let components = {};
    addressComponents.forEach((addressComponent) => {
      addressComponent.types.forEach((type) => {
        components[type] = addressComponent.long_name;
      });
    });

    return components;
  },

  getDataFromParseGoogleAddressComponents: (res: any) => {
    console.log("INCOMING REST--->", res);
    let resData: any = locatingFns.parseGoogleAddressComponents(
      res?.address_components
    );
    let state = "";
    let city = "";
    let country = "";
    let postCode = "";
    if (resData?.["administrative_area_level_1"]) {
      state = resData?.["administrative_area_level_1"];
    }
    if (resData?.["locality"]) {
      city = resData?.["locality"];
    } else if (resData?.["administrative_area_level_2"]) {
      city = resData?.["administrative_area_level_2"];
    } else if (resData?.["administrative_area_level_1"]) {
      city = resData?.["administrative_area_level_1"];
    }
    if (resData?.["country"]) {
      country = resData?.["country"];
    }
    if (resData?.["postal_code"]) {
      postCode = resData?.["postal_code"];
    }
    return { suburb: city, city: city, country: country, postCode: postCode };
  },

  extractAddress: (place) => {
    console.log("PLACE IS--->", place);
    const address = {
      locality: "",
      sublocality: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      plain() {
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        const subLocality = this.sublocality ? this.sublocality + ", " : "";
        const locality = this.locality ? this.locality + ", " : "";
        return subLocality + locality + city + state + zip + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("administrative_area_level_2")) {
        address.city = value;
      }
      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }
      if (types.includes("sublocality_level_3")) {
        address.sublocality = value;
      }
      if (types.includes("sublocality_level_2")) {
        address.sublocality = address.sublocality + `, ${value}`;
      }
      if (types.includes("sublocality_level_1")) {
        address.locality = value;
      }
      if (types.includes("postal_code")) {
        address.zip = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });
    return address;
  },
};

export default locatingFns;
