import React, { useEffect, useState } from 'react';
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import parse from "html-react-parser";
import { userService } from '../../../_services';
import { toast } from 'react-toastify';
import ToastHeader from "../../../components/toastHeader";
import { withRouter } from 'react-router-dom';

const Terms = (props) => {
    const [content, setContent] = useState('');
    useEffect(() => {
        userService.legalContent()
            .then((res) => {
                res.filter(i => i.contentType === "TNC").map((item) => {
                    let parsedContent: any = parse(item.content);
                    return setContent(parsedContent);
                });
            })
            .catch(function (error) {
                ToastHeader(error);
            });
    }, [])

    return (
        <div className="tab-pane fade show active" id="panel4" role="tabpane" aria-labelledby="panel4-tab">
            <div className="container-fluid">
                <div className='title-row'>
                    <h1>Terms &amp; Conditions</h1>
                </div>
                <div className="blue-shadow-box">
                    <div className="legal-content legal-scroll">
                        {content}</div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Terms);