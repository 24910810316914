import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { api } from "../../../middleware/api";
import {
  isOnlySpaces,
  UtilityFunctions,
} from "../../../utils/UtilityFunctions";
import { userService } from "../../../_services";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../../store";
import Sidebar from "../../../components/sidebar";
import ValidationErrorToast from "../../../components/validationErrorToast";
import ChatBubble from "../Chat/chatBubble";
import SuccessToast from "../../../components/successToast";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { cookieService } from "../../../_services";
import LastSeenTimer from "./LastSeenTimer";
import DetectInternet from "./DetectInternet";
import { useIsOnline } from "react-use-is-online";
import FlagConverstion from "./FlagConverstion";
import DeleteChat from "./DeleteChat";

const ChatScreen = (props: any) => {
  const state = useSelector((state: State) => state);
  const reconnectSocket = state?.chatReducer?.reconnect_inside_socket

  const dispatch = useDispatch();
  const { setSidebar, updateCurrentUserLastSeen, closeOnlineOfflineSocket, reconnectInsideSocket } =
    bindActionCreators(actionCreators, dispatch);
  const [dropDown, setDropDown] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [chatContent, setChatContent] = useState([]);
  const [show, setShow] = useState(false);
  const [messages, setMessages] = React.useState([]);
  const [isConnectionOpen, setConnectionOpen] = React.useState(false);
  const [messageBody, setMessageBody] = React.useState("");
  const [nextURL, setNextURL] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [chatInfo, setChatInfo] = useState<any>();
  const [closeModal, setCloseModal] = useState(false);
  const userType = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
  const [showFlagPopup, setShowFlagPopup] = useState(false);
  const [showDeleteChat, setShowDeleteChat] = useState(false);


  const ws = useRef<any>();
  const bottomRef = useRef(null);
  const msgRef = useRef<any>();
  const [toggleStatus, setToggleStatus] = useState<boolean>(false);
  const [userLastOnlineTime, setUserLastOnlineTime] = useState("");
  const { isOnline, isOffline, error } = useIsOnline();
  const firstStart = useRef(false);
  const tick = useRef<any>(null);
  const timerRef = useRef<any>(0);
  const isOpenRef = useRef(false);
  const selectedChatScreen = useRef(false);
  const [start, setStart] = useState(true);
  const stopSocketRef = useRef(false);
  const [chatActiveMessageUsers, setChatActiveMessageUsers] = useState([]);



  useEffect(() => {
    if (props?.chatSelected && props?.selectedChat?.id) {

      initWebSocket();
    }
    return () => {
      ws?.current?.close();
      ws.current = null;
    };
  }, [props?.chatSelected, props?.selectedChat?.id]);

  useEffect(() => {
    if (reconnectSocket) {

      ws?.current?.close();
      ws.current = null;
      initWebSocket()
      reconnectInsideSocket(false)
    }
  }, [reconnectSocket])

  const fetchMoreData = () => {
    setTimeout(() => {
      if (hasMore) {
        const nextPage = nextURL?.split(UtilityFunctions.API_URL())?.[1];
        api.get(nextPage, null).then((res) => {
          // console.log(res);
          if (res.next) {
            setNextURL(res.next);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          // console.log(chatContent);
          setChatContent((prevState) => [...prevState, ...res.results.chat]);
        });
      }
    }, 100);
  };
  const updateChatParticipants = (messages) => {
    var temp_array = [];
    messages.map(message => {
      if (!temp_array.includes(message.user)) {
        temp_array.push(message.user)
      }
    })
    setChatActiveMessageUsers(temp_array);
  }
  useEffect(() => {
    if (props?.selectedChat) {
      api
        .get(`${props?.selectedChat?.id}/chat/?limit=25`, null)
        .then((res) => {

          if (res?.results?.room?.isArchived) {
            setTimeout(() => {
              props?.setArchive(res?.results?.room?.isArchived);
            }, 1000);
          }
          if (res?.results?.room?.isFlagged) {
            setTimeout(() => {
              if (userType.user.type === STRING_CONSTANTS.userType.employee) {
                if (props?.selectedChat?.employeeFlagged) {
                  props?.setFlag(res?.results?.room?.isFlagged);
                }
              } else {
                if (props?.selectedChat?.employerFlagged) {
                  props?.setFlag(res?.results?.room?.isFlagged);
                }
              }

              // props?.setFlag(res?.results?.room?.isFlagged);
            }, 1000);
          }

          updateChatParticipants(res?.results?.chat)
          // setUserLastOnlineTime(res?.results?.user?.lastOnlineTime)
          updateCurrentUserLastSeen(res?.results?.user?.lastOnlineTime);
          setChatContent(res.results.chat);
          // console.log(res?.results?.room);
          !res?.results?.room?.employee ||
            !res?.results?.room?.employee?.employee ||
            !res?.results?.room?.employer?.employer ||
            !res?.results?.room?.employer
            ? setUserDeleted(true)
            : setUserDeleted(false);
          setChatInfo(res.results.room);
          if (res.next) {
            setNextURL(res.next);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          // console.log(res);
        })
        .then(() => props.reloadHandling && props.getChatList())
        .catch
        // (e) => console.log(e)
        ();
    }
  }, [props?.selectedChat]);
  useEffect(() => {
    props.setLatestMessage("");
    if (chatInfo) {
      if (
        chatInfo?.job?.status === "active" ||
        chatInfo?.job?.status === "cancelled"
      ) {
        setToggleStatus(false);
      } else {
        setToggleStatus(true);
      }
    }
  }, [chatInfo]);

  useEffect(() => {
    //console.log('I am adding mesaages')
    if (Object.keys(messages)) {
      if (messages.length > 0) {
        setChatContent([...[messages[messages.length - 1]], ...chatContent]);
      }
    }
  }, [messages]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ alignToTop: false });
  }, [chatContent, messages.length]);

  useEffect(() => {
    //console.log('Chat contemt is gettimg updated')
    setDropDown(false);
    props.setLatestMessage(chatContent[0]?.content);
    props.setLatestMessageTime(moment());
  }, [chatContent]);

  const adjustTheSendersList = (id) => {
    var temp_array = [...chatActiveMessageUsers];
    if (!temp_array.includes(id)) {
      temp_array.push(id)
    }
    setChatActiveMessageUsers(temp_array);
  }

  const submitMessage = (e) => {
    e.preventDefault();
    console.log(isOnline);
    if (isOnline) {
      if (
        !UtilityFunctions.isEmptyOrNullOrUndefined(messageBody) &&
        messageBody?.trim?.length <= 1000
      ) {
        msgRef.current.value = "";
        console.log(
          "message sent",
          userType?.user.type,
          STRING_CONSTANTS.userType.employer
        );
        if (ws?.current?.readyState === 1 && isOpenRef.current == true) {
          ws.current.send(
            JSON.stringify({
              message: messageBody?.trim(),
              user_id:
                userType?.user.type === STRING_CONSTANTS.userType.employer
                  ? props?.selectedChat?.employer?.id
                  : props?.selectedChat?.employee?.id,
            })
          );
          adjustTheSendersList(userType.user.id)
          setMessageBody("");
        } else {
          ValidationErrorToast(
            "Network",
            "Please wait while we are connecting"
          );
        }
      }
    } else {
      ValidationErrorToast("Network", "Your connection seems offline");
    }
  };


  const submitMessageDirect = (msg) => {
    if (isOnline) {
      if (msgRef.current === null) {
        return;
      }
      if (
        !UtilityFunctions.isEmptyOrNullOrUndefined(msg) &&
        msg?.trim?.length <= 1000
      ) {
        msgRef.current.value = "";
        console.log(
          "message sent",
          userType?.user.type,
          STRING_CONSTANTS.userType.employer
        );
        if (ws?.current?.readyState === 1 && isOpenRef.current == true) {
          ws.current.send(
            JSON.stringify({
              message: msg?.trim(),
              user_id:
                userType?.user.type === STRING_CONSTANTS.userType.employer
                  ? props?.selectedChat?.employer?.id
                  : props?.selectedChat?.employee?.id,
            })
          );
          adjustTheSendersList(userType.user.id)
        } else {
          ValidationErrorToast(
            "Network",
            "Please wait while we are connecting"
          );
        }
      }
    } else {
      ValidationErrorToast("Network", "Your connection seems offline");
    }
  };






  const allocateJob = () => {
    if (!toggleStatus) {
      setToggleStatus(true);
      setJobAllocated();
    } else {
      setUserDeleted(true);
      ValidationErrorToast("Allocated Job", "Job is already allocated");
    }
  };
  const sendToArchive = (chatID, archive) => {
    api
      .patch(`${chatID}/archive-chat/`, {
        archive: archive,
      })
      .then((res) => {

        // console.log("archive", res);
        //refreshes chat list
        props.setSelectedChat("");
        props?.getChatList();
        setDropDown(false);

        // console.log(res)
      })
      .catch
      // (e) => console.log(e)
      ();
  };

  const sendToFlag = (chatID, flag, flagReason = "") => {
    if (flag) {
      api.patch(`${chatID}/flag-chat/`, {
        flag_reason: flagReason
      })
        .then((res) => {
          //refreshes chat list
          // console.log("flag", res)
          props.setSelectedChat("");
          props?.getChatList();
          setDropDown(false);
          // console.log(res)
        })
        .catch
        // (e) => console.log(e)
        ();
    } else {
      api.patch(`${chatID}/flag-chat/`, { "un-flag": "True" })
        .then((res) => {
          //refreshes chat list
          // console.log("flag", res)
          props.setSelectedChat("");
          props?.getChatList();
          setDropDown(false);
          // console.log(res)
        })
        .catch
        // (e) => console.log(e)
        ();
    }
  }




  const setJobAllocated = () => {
    userService
      .setJobAllocated(chatInfo?.job?.id, {
        status: "allocated",
        allocatedEmployee: chatInfo.employee?.employee?.id,
      })
      .then((res) => {
        setDropDown(false);
        SuccessToast(
          "Successfully Allocated",
          `${chatInfo.job.title} role has been allocated to ${chatInfo?.employee?.employee?.firstName} `
        );
      })
      .catch((err) => { });
  };
  // const fetchEmployeeDetails = (eeID, jobID) => {
  //     api.get(`${eeID}/employee-employer/?jobId=${jobID}`, null)
  //         .then((res) => {
  //             setCloseModal(false)
  //         })
  //         .catch((e) => {
  //             // setUserDeleted(true)
  //             setSidebar({ sidebar_name: '', show_sidebar: true });
  //             setCloseModal(true)
  //             ValidationErrorToast(e[0], e[1])
  //         })
  // }

  const isUserOnline = (id) => {
    return state?.chatReducer?.online_user_list?.includes(id);
  };

  const initWebSocket = () => {
    if (props?.selectedChat?.id) {
      if (ws.current == null) {
        if (userType?.user.type === STRING_CONSTANTS.userType.employer) {
          ws.current = new WebSocket(
            `${UtilityFunctions.CHAT_URL()}${props?.selectedChat?.id}/?userId=${props?.selectedChat?.employer?.id
            }`
          );
        } else {
          ws.current = new WebSocket(
            `${UtilityFunctions.CHAT_URL()}${props?.selectedChat?.id}/?userId=${props?.selectedChat?.employee?.id ||
            props?.selectedChat?.employer?.id
            }`
          );
        }
        initiateListeners();
      }
    }
  };
  const initiateListeners = () => {
    ws.current.onopen = () => {
      console.log("onopen socket inside");
      isOpenRef.current = true;
      // console.log("Connection opened");
      //  setConnectionOpen(true);
      // alert("open")
    };
    ws.current.onclose = () => {
      console.log("close socket inside");
      isOpenRef.current = false;
      // alert("closed")
      // console.log("Connection closed");
      //  setConnectionOpen(false);
    };
    // Listening on ws new added messages
    ws.current.onmessage = (event) => {
      // console.log("message received");
      const data = JSON.parse(event.data);
      // console.log("nhgr567ytfghjkjhj", data);
      if (data?.type == "chat_message") {
        const new_mes = {
          createdAt: Date.now(),
          content: data?.message,
          user: data?.user_id,
          isRead: data?.is_read,
          id: data?.id,
        };
        setMessages((_messages) => [..._messages, new_mes]);
      }
      if (data?.type == "read_message_echo") {
        setChatContent((chatData) => {
          return chatData?.map((item, index) => {
            return {
              ...item,
              isRead: true,
            };
          });
        });
        // [...[messages[messages.length - 1]], ...chatContent]);

        // setMessages((chatData) => {
        //   return chatData?.map((item, index) => {
        //     return {
        //       ...item,
        //       isRead: true,
        //     };
        //   });
        // });
      }
      if (data?.type == "delivery_report_echo") {
        if (data?.delivered) {
          setChatContent((chatData) => {
            return chatData?.map((item, index) => {
              return {
                ...item,
                delivered: true,
              };
            });
          });
        }
      }
      if (data?.type == "delivery_message_echo") {
        if (data?.delivered) {
          setTimeout(() => {
            setChatContent((chatData) => {
              return chatData?.map((item, index) => {
                if (item?.id == data?.id) {
                  // Alert.alert('jhgfu');
                  return {
                    ...item,
                    delivered: true,
                  };
                } else {
                  return item;
                }
              });
            });
          }, 1000);
        }
      }
    };
    ws.current.onerror = (error) => {
      console.log("close socket inside");
      isOpenRef.current = false;
      // console.log(error)
      //  setConnectionOpen(false);
    };
  };

  // console.log("chatinfo", chatInfo);
  console.log("selectedchat", props.selectedChat);
  console.log("employer", userType?.user.type === STRING_CONSTANTS.userType.employer, chatInfo?.employeeFlagged, chatInfo?.employerFlagged, props.chatList)
  return (
    <div>
      {/* <h1 style={{color:"white"}}>{isOpenRef.current==true?"Inside socket connected":"Inside socket disconnected"}</h1> */}
      {props?.selectedChat ? (
        <>
          <div className="chat-screen-top-bar">
            <div className="main-bar">
              {userType.user.type === STRING_CONSTANTS.userType.employer ? (
                <div className="left-side">
                  <img
                    style={{ cursor: `${userDeleted ? "" : "pointer"}` }}
                    src={
                      chatInfo?.employee?.employee?.profilePhoto ||
                      UtilityFunctions.getImageURL() + "chat-list-profile.png"
                    }
                    onClick={() =>
                      userDeleted ? setShow(false) : setShow(true)
                    }
                  />
                  <div className="right-con">
                    {userDeleted ? (
                      <>
                        <h5> User Deleted</h5>
                        <h5 className="last-seenst ps-2">
                          User has been deleted
                        </h5>
                      </>
                    ) : (
                      <>
                        <h5
                          style={{ cursor: "pointer" }}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          onClick={() => {
                            // fetchEmployeeDetails(chatInfo?.employee?.employee.id, chatInfo?.job?.id)
                            setCloseModal(false);
                            setSidebar({
                              sidebar_name: "employee-details",
                              show_sidebar: true,
                              uniqueId: chatInfo?.job?.id,
                              uniqueemployeeId: chatInfo?.employee?.employee.id,
                            });
                          }}
                        >
                          {chatInfo?.employee?.employee?.firstName}
                        </h5>
                      </>
                    )}

                    {/* <p>Active Now</p> */}
                    {chatInfo?.job?.status === "cancelled" ? (
                      <h5 className="last-seenst ps-2">
                        Job has been cancelled
                      </h5>
                    ) : (
                      <LastSeenTimer
                        chatInfo={chatInfo?.employee?.id}
                        last_seen={state?.chatReducer?.last_seen}
                        online_user_list={state?.chatReducer?.online_user_list}
                      />
                    )}
                    {/* {isUserOnline(chatInfo?.employee?.id)?<p>Active Now</p>:<h5>{`last seen ${moment(state?.chatReducer?.last_seen).fromNow()}`}</h5>} */}
                  </div>
                </div>
              ) : (
                <div className="left-side">
                  <img
                    style={{ cursor: `${userDeleted ? "" : "pointer"}` }}
                    src={
                      chatInfo?.employer?.employer?.logo ||
                      UtilityFunctions.getImageURL() + "bildeing-squre-img.png"
                    }
                    onClick={() =>
                      userDeleted ? setShow(false) : setShow(true)
                    }
                  />
                  <div className="right-con">
                    {userDeleted ? (
                      <>
                        <h5> User Deleted</h5>
                        <h5 className="last-seenst ps-2">
                          User has been deleted
                        </h5>
                      </>
                    ) : (
                      <>
                        <h5
                          style={{ cursor: "pointer" }}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          onClick={() => {
                            // fetchEmployeeDetails(chatInfo?.employer?.employer.id, chatInfo?.job?.id)
                            setCloseModal(false);
                            setSidebar({
                              sidebar_name: "employee-details",
                              show_sidebar: true,
                              uniqueId: chatInfo?.employer?.employer.id,
                            });
                          }}
                        >
                          {chatInfo?.employer?.employer?.tradingName}
                        </h5>
                      </>
                    )}
                    {chatInfo?.job?.status === "cancelled" ? (
                      <h5 className="last-seenst ps-2">
                        Job has been cancelled
                      </h5>
                    ) : (
                      <LastSeenTimer
                        chatInfo={chatInfo?.employer?.id}
                        last_seen={state?.chatReducer?.last_seen}
                        online_user_list={state?.chatReducer?.online_user_list}
                      />
                    )}
                    {/* {isUserOnline(chatInfo?.employer?.id)?<p>Active Now</p>:<h5>{`last seen ${moment(state?.chatReducer?.last_seen).fromNow()}`}</h5>} */}
                  </div>
                </div>
              )}



              <div className="right-sidebar">
                {(userType.user.type === STRING_CONSTANTS.userType.employer) ?

                  !props?.selectedChat?.employeeFlagged ?
                    <>
                      {(!chatInfo?.isArchived || !chatInfo?.isFlagged ||
                        (!chatInfo?.isArchived && !chatInfo?.isFlagged &&
                          chatInfo?.job?.status === "active" &&
                          !userDeleted &&
                          !toggleStatus)) && (
                          <div
                            className={` main-dropdown ${dropDown && "active"}`}
                            onClick={() => setDropDown((prev) => !prev)}
                          >

                            <img
                              src={
                                UtilityFunctions.getImageURL() +
                                "three-dots-line-white.svg"
                              }
                              alt=""
                              style={{ cursor: "pointer" }}
                            />
                            {
                              <ul>
                                {(!props.archive && !props.flag) && (
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        sendToArchive(props?.selectedChat?.id, true)
                                      }
                                    >
                                      Archive Conversation
                                    </button>
                                  </li>
                                )}
                                {props.archive && (
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        sendToArchive(props?.selectedChat?.id, false)
                                      }
                                    >
                                      Unarchive Conversation
                                    </button>
                                  </li>
                                )}

                                {/* {!userDeleted &&
                        chatInfo?.job?.status === "active" &&
                        userType.user.type ===
                          STRING_CONSTANTS.userType.employer &&
                        !toggleStatus ? (
                          <li>
                            <button
                              type="button"
                              onClick={(e) => {
                                setJobAllocated();
                                setToggleStatus(true);
                              }}
                            >
                              Allocate Job
                            </button>
                          </li>
                        ) : null} */}


                                {(!chatInfo?.isFlagged && !props?.archive) &&
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        // sendToFlag(props?.selectedChat?.id, true)
                                        setShowFlagPopup(true)
                                      }
                                    >
                                      Flag Conversation
                                    </button>
                                  </li>
                                }
                                {(chatInfo?.isFlagged) &&
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        sendToFlag(props?.selectedChat?.id, false)

                                      }
                                    >
                                      Unflag Conversation
                                    </button>
                                  </li>
                                }
                                <li>
                                  <button
                                    type="button"
                                    onClick={() => { setShowDeleteChat(true) }

                                    }
                                  >
                                    Delete Conversation
                                  </button>
                                </li>

                              </ul>
                            }
                          </div>
                        )}
                    </>
                    : "" :

                  (userType.user.type === STRING_CONSTANTS.userType.employee) ?

                    !props?.selectedChat?.employerFlagged ?
                      <>
                        {(!chatInfo?.isArchived || !chatInfo?.isFlagged ||
                          (!chatInfo?.isArchived && !chatInfo?.isFlagged &&
                            chatInfo?.job?.status === "active" &&
                            !userDeleted &&
                            !toggleStatus)) && (
                            <div
                              className={` main-dropdown ${dropDown && "active"}`}
                              onClick={() => setDropDown((prev) => !prev)}
                            >

                              <img
                                src={
                                  UtilityFunctions.getImageURL() +
                                  "three-dots-line-white.svg"
                                }
                                alt=""
                                style={{ cursor: "pointer" }}
                              />
                              {
                                <ul>
                                  {(!props.archive && !props.flag) && (
                                    <li>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          sendToArchive(props?.selectedChat?.id, true)
                                        }
                                      >
                                        Archive Conversation
                                      </button>
                                    </li>
                                  )}
                                  {props.archive && (
                                    <li>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          sendToArchive(props?.selectedChat?.id, false)
                                        }
                                      >
                                        Unarchive Conversation
                                      </button>
                                    </li>
                                  )}

                                  {/* {!userDeleted &&
                        chatInfo?.job?.status === "active" &&
                        userType.user.type ===
                          STRING_CONSTANTS.userType.employer &&
                        !toggleStatus ? (
                          <li>
                            <button
                              type="button"
                              onClick={(e) => {
                                setJobAllocated();
                                setToggleStatus(true);
                              }}
                            >
                              Allocate Job
                            </button>
                          </li>
                        ) : null} */}


                                  {(!chatInfo?.isFlagged && !props?.archive) &&
                                    <li>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          // sendToFlag(props?.selectedChat?.id, true)
                                          setShowFlagPopup(true)
                                        }
                                      >
                                        Flag Conversation
                                      </button>
                                    </li>
                                  }
                                  {(chatInfo?.isFlagged) &&
                                    <li>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          sendToFlag(props?.selectedChat?.id, false)

                                        }
                                      >
                                        Unflag Conversation
                                      </button>
                                    </li>
                                  }
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() => { setShowDeleteChat(true) }

                                      }
                                    >
                                      Delete Conversation
                                    </button>
                                  </li>

                                </ul>
                              }
                            </div>
                          )}
                      </> : ""
                    : ""
                }

              </div>

            </div>
            {/* <div className="connect-toggles">
              <div className="movtoal">
                {userType.user.type === STRING_CONSTANTS.userType.employer && (
                  <>
                    <p>Do you want to allocate this job?</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={toggleStatus}
                        onClick={allocateJob}
                        disabled={
                          userDeleted
                            ? true
                            : chatInfo?.job?.status === "active" &&
                              !toggleStatus
                            ? false
                            : true
                        }
                      />

                      <span className="slider round"></span>
                    </label>
                  </>
                )}
              </div>
            </div> */}
          </div>
          <div className="full-cheat-section">

            <div className="all-cheat-content">
              <ChatBubble
                chatContent={chatContent}
                selectedChat={props.selectedChat}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                setMessageBody={setMessageBody}
                submitMessage={submitMessage}
                submitMessageDirect={submitMessageDirect}
                isOpenRef={isOpenRef}
                refresh={props.refresh}
                setRefresh={props.setRefresh}
                chatActiveMessageUsers={chatActiveMessageUsers}
              />

            </div>


            {chatInfo?.job?.status === "cancelled" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h6
                  style={{
                    color: "white",
                  }}
                >
                  This job has been cancelled
                </h6>
              </div>
            ) : chatInfo === undefined ? (
              <div></div>
            ) : !chatInfo?.job ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h6
                  style={{
                    color: "white",
                  }}
                >
                  This job has been deleted
                </h6>
              </div>
            ) : userDeleted ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h6
                  style={{
                    color: "white",
                  }}
                >
                  This user doesn't exist
                </h6>
              </div>
            ) : (
              // :   !isConnectionOpen||!isOnline ? (
              //   <div
              //     style={{
              //       display: "flex",
              //       justifyContent: "center",
              //     }}
              //   >
              //     <h6
              //       style={{
              //         color: "white",
              //       }}
              //     >
              //       Please wait while we connect you to the chat...
              //     </h6>
              //   </div>
              // )



              <div className="main-btn">
                {
                  userType.user.type === STRING_CONSTANTS.userType.employer ?
                    !props?.selectedChat?.employeeFlagged ?

                      !props.flag ?
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            // !props.refresh && props.setRefresh(true);
                            // submitMessage(e);
                            if (isOpenRef.current == true) {
                              !props.refresh && props.setRefresh(true);
                              submitMessage(e);
                            } else {
                              ValidationErrorToast(
                                "Network",
                                "Please wait while we are connecting"
                              );
                            }
                          }}
                        >

                          <input
                            maxLength={1000}
                            type="text"
                            placeholder={"Type message..."}
                            ref={msgRef}
                            // value={messageBody}
                            onChange={(e) => setMessageBody(e.target.value)}
                          />
                          <button
                            style={{
                              opacity:
                                !messageBody || isOnlySpaces(messageBody) ? 0.5 : 1,
                            }}
                            disabled={!messageBody || isOnlySpaces(messageBody)}
                            type="button"
                            onClick={(e) => {
                              if (isOpenRef.current == true) {
                                !props.refresh && props.setRefresh(true);
                                submitMessage(e);
                              } else {
                                ValidationErrorToast(
                                  "Network",
                                  "Please wait while we are connecting"
                                );
                              }
                            }}
                          // disabled={!isConnectionOpen}
                          >
                            Send
                            <img
                              src={UtilityFunctions.getImageURL() + "Send.svg"}
                              alt=""
                            />
                          </button>
                        </form>
                        : <div className="d-flex align-items-center justify-content-center mt-5">
                          <div className="flagged-user mx-auto">
                            You can't chat with this user as you have flagged the conversation, click on unflag to resume the conversation</div>
                        </div>
                      : <div className="d-flex align-items-center justify-content-center mt-5">
                        <div className="flagged-user mx-auto">
                          you can’t message this user anymore, this user has revoked the communication. thanks for the understanding</div>
                      </div>
                    :
                    userType.user.type === STRING_CONSTANTS.userType.employee ?
                      !props?.selectedChat?.employerFlagged ?

                        !props.flag ?
                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              // !props.refresh && props.setRefresh(true);
                              // submitMessage(e);
                              if (isOpenRef.current == true) {
                                !props.refresh && props.setRefresh(true);
                                submitMessage(e);
                              } else {
                                ValidationErrorToast(
                                  "Network",
                                  "Please wait while we are connecting"
                                );
                              }
                            }}
                          >

                            <input
                              maxLength={1000}
                              type="text"
                              placeholder={"Type message..."}
                              ref={msgRef}

                              onChange={(e) => setMessageBody(e.target.value)}
                            />
                            <button
                              style={{
                                opacity:
                                  !messageBody || isOnlySpaces(messageBody) ? 0.5 : 1,
                              }}
                              disabled={!messageBody || isOnlySpaces(messageBody)}
                              type="button"
                              onClick={(e) => {
                                if (isOpenRef.current == true) {
                                  !props.refresh && props.setRefresh(true);
                                  submitMessage(e);
                                } else {
                                  ValidationErrorToast(
                                    "Network",
                                    "Please wait while we are connecting"
                                  );
                                }
                              }}
                            // disabled={!isConnectionOpen}
                            >
                              Send
                              <img
                                src={UtilityFunctions.getImageURL() + "Send.svg"}
                                alt=""
                              />
                            </button>
                          </form>
                          : <div className="d-flex align-items-center justify-content-center mt-5">
                            <div className="flagged-user mx-auto">
                              You can't chat with this user as you have flagged the conversation, click on unflag to resume the conversation</div>
                          </div>
                        : <div className="d-flex align-items-center justify-content-center mt-5">
                          <div className="flagged-user mx-auto">
                            you can’t message this user anymore, this user has revoked the communication. thanks for the understanding</div>
                        </div>
                      : ""
                }


              </div>
            )}
          </div>
          <Modal
            className="bg-transparent logout-popup profile-pic-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShow(false)}
            show={show}
          >
            <Modal.Body>
              <Button
                className="cross-btn"
                variant="primary"
                onClick={() => setShow(false)}
              >
                <img
                  src={UtilityFunctions.getImageURL() + "cross-icon-white.svg"}
                  className="cross-icon bg-transparent"
                  alt=""
                />
              </Button>
              <img
                alt="logout"
                src={
                  userType?.user?.type === STRING_CONSTANTS.userType.employee
                    ? chatInfo?.employer?.employer?.logo ||
                    UtilityFunctions.getImageURL() + "tab-building-defalt.svg"
                    : chatInfo?.employee?.employee?.profilePhoto ||
                    UtilityFunctions.getImageURL() + "chat-list-profile.png"
                }
              />
            </Modal.Body>
          </Modal>
          <FlagConverstion
            show={showFlagPopup}
            handleClose={() => setShowFlagPopup(false)}
            sendToFlag={sendToFlag}
            selectedChat={props.selectedChat}

          />
          <DeleteChat
            show={showDeleteChat}
            handleClose={() => setShowDeleteChat(false)}
            getChatList={() => props.getChatList()}
            selectedChat={props?.selectedChat}
            setSelectedChat={props.setSelectedChat}
          />
          <Sidebar
            history={props.history}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
            selectedEmployee={
              userType?.user?.type === STRING_CONSTANTS.userType.employer
                ? props?.employeeDetails
                : chatInfo?.employee?.employee
            }
            selectedJobID={chatInfo?.job?.id}
          />
        </>
      ) : props?.chatListLength < 1 ? (
        <div className="default-setting">
          <img
            src={UtilityFunctions.getImageURL() + "default-setting.svg"}
            alt=""
          />
          <h5>
            It looks like you haven’t received a <br />
            chat request yet. Return to home
            <br /> and find potental employee.
          </h5>
        </div>
      ) : (
        <div className="default-setting">
          <img
            src={UtilityFunctions.getImageURL() + "default-setting.svg"}
            alt=""
          />
          <h5>
            Select a chat to view <br /> conversation
          </h5>
        </div>
      )}
    </div>
  );
};

export default withRouter(ChatScreen);
