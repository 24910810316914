import { cookieService } from '../_services';
import ToastHeader from "../components/toastHeader";
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';


// A function to handle the errors in case cookies are cleared after log in
export const checkData = (props: any, path: any, error) => {
    if (!cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) || !cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)) {
        ToastHeader(['You have been logged out!']);
        // Fallthrough case to clear all cookies from the browser
        cookieService.removeAllCookies()
        props.history.push(path);
    }
    else error && ToastHeader(error);
}