import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";

import SignUp from "../pages/Signup/index";
import Login from "../pages/SignIn";
import ForgotPassword from "../pages/forgotPassword";
import ResetPassword from "../pages/resetPassword";
import Dashboard from "../pages/Dashboard/dashboard";

// legal
import Terms from "../pages/legal/terms";
import Privacy from "../pages/legal/privacy";
import Test from "../test";
import TestNew from "../testnew";
import Onboarding from "../pages/Onboarding";
import OnboardingTutorial from "../pages/Dashboard/Settings/OnboardingTutorial";
import GuardianDocumentUpload from "../pages/GuardianDocumentUpload";
import EmployerProfileCompletion from "../pages/EmployerProfileCompletion/index";
import EmployeeProfileCompletion from "../pages/EmployeeProfileCompletion/index";
import EmployeeAdvancedProfile from "../pages/EmployeeAdvancedProfile/index";
import JobCreation from "../pages/Dashboard/Job Creation/index";
import Settings from "../pages/Dashboard/Settings/index";
import Chat from "../pages/Dashboard/Chat/index";
import NotificationScreen from "../pages/Dashboard/Notifications/index";
import { actionCreators, State } from "../store";
import { bindActionCreators } from "redux";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { cookieService, userService } from "../_services";
import { api } from "../middleware/api";
import { END_POINTS } from "../utils/constants/endPoints";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import ToastHeader from "../components/toastHeader";
import { Data } from "../_services/collections";
import EmployeeSignup from "../pages/Signup/EmployeeSignup";
import Employees from "../pages/LandingPages/Employees";
import Employers from "../pages/LandingPages/Employers";
import AccountsReceivable from "../pages/LandingPages/AccountsReceivable";
import MPLOITrainer from "../pages/LandingPages/MPLOITrainer";
import MPLOILegalAssist from "../pages/LandingPages/MPLOILegalAssist";
import MPLOIHR from "../pages/LandingPages/MPLOIHR";
import Blogs from "../pages/LandingPages/Blogs";
import SocketClient from "../library/SocketClient";
import Verified from "../pages/Dashboard/verified/Verified";
import Failed from "../pages/Dashboard/verified/Failed";

import SubscriptionWeb from "../pages/SubscriptionWeb";
import About from "../pages/About/About";
import Competition from "../pages/Competition/Competition";
import Contact from "../pages/Contact/Contact";
import Pricing from "../pages/Pricing/Pricing";
import BlogDetails from "../pages/BlogDetails/BlogDetails";
import InterviewSimulatorPage from "../pages/InterviewSimulatorPages/InterviewSimulator/InterviewSimulatorPage";
import InterviewPreparationPage from "../pages/InterviewSimulatorPages/InterviewSimulator/InterviewPreparationPage";
import HRAssisstentPage from "../pages/InterviewSimulatorPages/HrSimulatorPages/HRAssisstentPage";
import HrAvatarMode from "../pages/InterviewSimulatorPages/HrSimulatorPages/HrAvatarMode";
import Results from "../pages/InterviewSimulatorPages/InterviewSimulator/Results";
import PitchingResults from "../pages/InterviewSimulatorPages/TrainerSimulatorPages/PitchingResults";

import InterviewModePage from "../pages/InterviewSimulatorPages/InterviewSimulator/InterviewModePage";
import TrainerPitchingPage from "../pages/InterviewSimulatorPages/TrainerSimulatorPages/TrainerPitchingPage";
import TrainerIntroPage from "../pages/InterviewSimulatorPages/TrainerSimulatorPages/TrainerIntroPage";
import TrainerModePage from "../pages/InterviewSimulatorPages/TrainerSimulatorPages/TrainerModePage";
import PitchingPage from "../pages/InterviewSimulatorPages/TrainerSimulatorPages/PitchingPage";

const AllRoutes = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: State) => state);
  const { setUnreadNotificationCount, setReferralCode } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const userTypeCookieval = cookieService.getCookie(
    STRING_CONSTANTS.cookieStrings.user
  );
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  const regDeviceToken = () => {
    api
      .post("device-token/", {
        deviceType: "WEB",
        user: `${
          (cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) as Data)
            .user.id
        }`,
        token: `${cookieService.getCookie("fcm")}`,
      })
      .then
      // (res) => console.log(res)
      ()
      .catch
      // (e) => console.log(e)
      ();
  };

  useEffect(() => {
    if (userTypeCookieval?.user?.id) {
      setTimeout(() => {
        setUserLoggedIn(true);
      }, 2000);
    } else {
      setTimeout(() => {
        setUserLoggedIn(false);
      }, 2000);
    }
  }, [userTypeCookieval?.user?.id]);

  useEffect(() => {
    const urlString = window?.location.href;

    let paramString = urlString?.split("?")?.[1];
    let params_arr = paramString?.split("&");
    let keyArr = [];
    let valueArr = [];
    let uriMap = new Map();
    const user = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
    const token = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    for (let i = 0; i < params_arr?.length; i++) {
      let pair = params_arr?.[i]?.split("=");
      keyArr.push(pair?.[0]);
      valueArr.push(pair?.[1]);
      uriMap.set(pair[0], pair[1]);
    }
    if (uriMap?.has("uidb64") && uriMap?.has("token")) {
      api
        .post(
          `${END_POINTS.login}?uidb64=${uriMap.get(
            "uidb64"
          )}&token=${uriMap.get("token")}`,
          null
        )
        .then((res: any) => {
          let response = res?.data?.user?.token?.access;
          cookieService.saveCookie(
            STRING_CONSTANTS.cookieStrings.user,
            res.data
          );
          cookieService.saveCookie(
            STRING_CONSTANTS.cookieStrings.token,
            response
          );
          setUnreadNotificationCount(res?.data?.user?.unreadNotificationCount);
        })
        .then(() => {
          window.location.replace(STRING_CONSTANTS.navigationString.index);
          if (cookieService.getCookie("fcm")) {
            regDeviceToken();
          }
        })
        .catch(function (error) {
          //debugger
          ToastHeader(error);
        });
    } else if (uriMap?.has("referral_code")) {
      setReferralCode(uriMap.get("referral_code"));
    } else if (
      uriMap?.has("advance_profile") &&
      uriMap?.has("user_id") &&
      user?.user?.type === STRING_CONSTANTS.userType.employee
    ) {
      if (user?.user && token && user?.user?.id == uriMap?.get("user_id")) {
        if (user?.isAdvanceProfileCompleted) {
          window.location.href = `${STRING_CONSTANTS.navigationString.advanced_profile}?source=notification`;
        } else {
          window.location.href = `${STRING_CONSTANTS.navigationString.advanced_profile}`;
        }
      }
    }
  }, [window?.location?.href]);

  useEffect(() => {
    if (cookieService.getCookie("unreadCount")) {
      setUnreadNotificationCount(cookieService.getCookie("unreadCount"));
    } else {
      if (cookieService.getCookie("token") && cookieService.getCookie("user")) {
        let cookie = cookieService.getCookie("user");
        setUnreadNotificationCount(cookie?.user?.unreadNotificationCount);
        cookieService.saveCookie(
          "unreadCount",
          cookie?.user?.unreadNotificationCount
        );
      }
    }
  }, []);

  return (
    <React.Fragment>
      {/* {cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)?.user?.type} */}
      <ToastContainer
        position="top-right"
        style={{ zIndex: 9999999 }}
        hideProgressBar
        //  autoClose={false}
      />
      {isUserLoggedIn && <SocketClient />}
      <Router>
        <Switch>
          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.login}
            component={Login}
          />

          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.index}
            component={Onboarding}
          />
          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.forgotpassword}
            component={ForgotPassword}
          />
          <Route exact path={"/employeesignup"} component={EmployeeSignup} />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.privacy}
            component={Privacy}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.terms}
            component={Terms}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.signup}
            component={SignUp}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.about}
            component={About}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.competition}
            component={Competition}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.contact}
            component={Contact}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.pricing}
            component={Pricing}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.blog_details}
            component={BlogDetails}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.interview_Simulator_Page}
            component={InterviewSimulatorPage}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.interview_Preparation_Page}
            component={InterviewPreparationPage}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.results}
            component={Results}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.interview_Mode_page}
            component={InterviewModePage}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.hr_assisstent_page}
            component={HRAssisstentPage}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.hr_avatar_mode}
            component={HrAvatarMode}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.trainer_pitching_page}
            component={TrainerPitchingPage}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.trainer_intro_page}
            component={TrainerIntroPage}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.trainer_mode_page}
            component={TrainerModePage}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.pitching_page}
            component={PitchingPage}
          />

          <Route
            exact
            path={STRING_CONSTANTS.navigationString.pitching_results}
            component={PitchingResults}
          />

          <Route
            exact
            path={
              STRING_CONSTANTS.navigationString.resetpassword + "/:uidb/:token"
            }
            component={ResetPassword}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.guardianDocumentUpload}
            component={GuardianDocumentUpload}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.dashboard.home}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.onboardingTutorial}
            component={OnboardingTutorial}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.employer_profile_completion}
            component={EmployerProfileCompletion}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.employee_profile_completion}
            component={EmployeeProfileCompletion}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.advanced_profile}
            component={EmployeeAdvancedProfile}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.dashboard.settings}
            component={Settings}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.dashboard.job_creation}
            component={JobCreation}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.dashboard.chat}
            component={Chat}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.dashboard.notifications}
            component={NotificationScreen}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.dashboard.verified}
            component={Verified}
          />
          <PrivateRoute
            exact
            path={STRING_CONSTANTS.navigationString.dashboard.failed}
            component={Failed}
          />
          <Route
            exact
            path={STRING_CONSTANTS.navigationString.subscription_web}
            component={SubscriptionWeb}
          />
          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.test}
            component={Test}
          />
          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.for_employees}
            component={Employees}
          />
          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.for_employers}
            component={Employers}
          />
          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.blogs}
            component={Blogs}
          />

          <PublicRoute
            exact
            path={STRING_CONSTANTS.navigationString.testNew}
            component={TestNew}
          />

          <PublicRoute
            exact
            path="/accounts"
            component={AccountsReceivable}
          />

          <PublicRoute
            exact
            path="/hr"
            component={MPLOIHR}
          />

          <PublicRoute
            exact
            path="/trainer"
            component={MPLOITrainer}
          />

          <PublicRoute
            exact
            path="/legal"
            component={MPLOILegalAssist}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default AllRoutes;
