import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useSwiper } from "swiper/react";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { cookieService } from "../../_services";

const OnboardingScreen = (props: any) => {
  const swiper = useSwiper();
  if (props.index === props.indexOfLastSlide) {
    return (
      <section
        className="main-wrap"
        style={{ backgroundImage: `url("${props.image}")` }}
      >
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-12">
            <div className="left-wrap">
              <div className="logo-wrap">
                <img alt={props.title} src={props.logo} />
              </div>
              <div className="caption-wrap">
                <h2>{props.title}</h2> <p>{props.subtitle}</p>
              </div>
              <div className="progress-wrap d-none d-lg-block"> </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-12">
            <div className="right-wrap">
              <div className="btn-maccount">
                <Link to={STRING_CONSTANTS.navigationString.login}>
                  <button>
                    {STRING_CONSTANTS.onboardingScreen.buttons.account_exists}
                  </button>
                </Link>
              </div>
              <div className="btn-nextSkip">
                <Link to={STRING_CONSTANTS.navigationString.dashboard.home}>
                  <button className="me-3 btn-login" onClick={() => cookieService.saveCookie("isGuest", true)}>
                    Explore Now
                    {/* {STRING_CONSTANTS.onboardingScreen.buttons.login} */}
                  </button>
                </Link>
                {/* <Link > */}
                <button className="next-btn"
                  onClick={() => props.history.push({
                    pathname: STRING_CONSTANTS.navigationString.signup,
                    state: { account_exists: true }
                  })}
                >
                  {STRING_CONSTANTS.onboardingScreen.buttons.signup}
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  } else
    return (
      <section
        className="main-wrap"
        style={{ backgroundImage: `url("${props.image}")` }}
      >
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-12">
            <div className="left-wrap">
              <div className="logo-wrap">
                <img alt={props.title} src={props.logo} onClick={() => props?.history?.push('/')} />
              </div>
              <div className="caption-wrap">
                <h2>{props.title}</h2> <p>{props.subtitle}</p>
              </div>
              <div className="progress-wrap d-none d-lg-block"> </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-12">
            <div className="right-wrap">
              <div className="btn-maccount d-none d-md-block">
                <Link to={STRING_CONSTANTS.navigationString.login}>
                  <button>
                    {STRING_CONSTANTS.onboardingScreen.buttons.account_exists}
                  </button>
                </Link>
              </div>
              <div className="btn-nextSkip">
                <button
                  className="me-3 text-white next-btn border-0 bg-transparent skip-text"
                  onClick={() => swiper.slideTo(props.indexOfLastSlide)}
                >
                  {STRING_CONSTANTS.onboardingScreen.buttons.skip_all}
                </button>
                <button className="next-btn" onClick={() => swiper.slideNext()}>
                  {STRING_CONSTANTS.onboardingScreen.buttons.next_screen}
                  <img
                    src={UtilityFunctions.getImageURL() + "arrow_right.svg"}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default withRouter(OnboardingScreen);
