import React, { useEffect, useState } from "react";
import { countryList } from '../src/utils/constants/data'
import { UtilityFunctions } from "./utils/UtilityFunctions";
export default function TestNew() {
    return <>
        <div className="after-emploer-design min-vh-100 d-flex align-items-center justify-content-center">
            <div className="after-emploer-inner-des mx-auto">
                <h3><span>Welcome!</span> It seems you are after an Employer profile</h3>
                <p>Please download the app from your respective app stores to create your account.</p>
                <ul className="list-unstyled ps-0 ms-0 mb-0 d-flex align-items-center justify-content-center">
                    <li><img src={UtilityFunctions.getImageURL() + "mobile-app-store-badges.png"} alt="" /></li>
                    <li><img src={UtilityFunctions.getImageURL() + "mobile-google-play-badges.png"} alt="" /></li>
                </ul>
            </div>
        </div>
    </>
}
// export default function TestNew() {
//     const [country, setCountry] = useState<any>();
//     const [phone, setPhone] = useState<any>('')
//     const [showScroll, setShowScroll] = useState(false)
//     const [searchKey, setSearchKey] = useState<any>("")
//     const [finalList, setFinalList] = useState([])

//     const searchTerm = event => {
//         setSearchKey(event.key)
//     }

//     useEffect(() => {
//         if (showScroll) {
//             window.addEventListener('keydown', searchTerm);

//             // cleanup this component
//             return () => {
//                 window.removeEventListener('keydown', searchTerm);
//             }
//         }
//     }, [showScroll, searchKey])

//     useEffect(() => {
//         countryList.map((country) => {
//             if (country?.name === "Australia") {
//                 setCountry(country)
//             }
//         })
//     }, [])

//     useEffect(() => {
//         if (searchKey != '') {
//             setFinalList(countryList.filter(country => {
//                 return country.name[0].toLowerCase() === searchKey
//             }))
//         }
//         else setFinalList(countryList)
//     }, [searchKey])

//     const renderDiv = finalList?.map((country) => {
//         return (
//             <div style={{ borderRadius: '10px', width: "350px", height: "100%", position: "relative", display: `${showScroll ? '' : 'none'}` }} onClick={(e) => { setCountry(country) }}>
//                 <span style={{ fontSize: "1.85rem" }}>{country.flag}</span>&nbsp;&nbsp;
//                 <span >{country.name}</span>
//             </div>
//         )
//     })

//     return <>
//         <div style={{ display: "inline", width: "350px" }}>
//             <span onClick={() => {
//                 setShowScroll((prev) => {
//                     if (!showScroll) { setSearchKey("") }
//                     return !prev;
//                 })
//             }}>
//                 <span style={{ fontSize: "1.8rem" }}>
//                     {country?.flag}
//                 </span>
//                 &nbsp;
//                 &nbsp;
//                 <span >
//                     {country?.dial_code}
//                 </span>
//             </span>
//             &nbsp;
//             &nbsp;
//             <input placeholder="00 000 000" style={{ width: '203px' }} type="text" onClick={() => {
//                 setShowScroll(false)
//                 setSearchKey("")
//             }
//             } onChange={(e) => { setPhone(e.target.value) }} />
//         </div>
//         <div style={{
//             height: "197px", width: "352px", display: "flex", flexDirection: "column",
//             overflowY: "auto"
//         }}>
//             {renderDiv}
//         </div>
//     </>
// }
