import React, { useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { api } from "../../../middleware/api";
import { dataURLtoFile, UtilityFunctions } from '../../../utils/UtilityFunctions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import UpdateAbn from "../../../components/updateAbn";
import UpdateEmailNumber from "../../../components/updateEmailNumber";
import UpdateNumber from "../../../components/updateNumber"
import SuccessToast from "../../../components/successToast";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import ImageCrop from "../../../common/ui/ImageCrop";
import { forEach } from 'lodash';
import { cookieService } from "../../../_services";


const UpdateProfileEmployer = (props: any) => {
    // API data
    const [remoteUserData, setRemoteUserData] = useState<any>();
    const [submitData, setSubmitData] = useState<any>({});
    const [imageSubmit, setImageSubmit] = useState<any>(false)

    // error and focus states
    const [errorState, setErrorState] = useState({ email: false, number: false, business: false, trading: false, contactName: false, contactNo: false })
    const [focusState, setFocusState] = useState({ email: false, number: false, business: false, trading: false, contactName: false, contactNo: false })

    // for input refs and states
    const emailRef = useRef<any>();
    const businessRef = useRef<any>();
    const tradingRef = useRef<any>();
    const contactRef = useRef<any>();
    const addressRef = useRef<any>();
    const [email, setEmail] = useState<any>();
    const [businessName, setBusinessName] = useState<any>();
    const [tradingName, setTradingName] = useState<any>();
    const [contactName, setContactName] = useState<any>();
    const [number, setNumber] = useState<any>();
    const [contactNumber, setContactNumber] = useState<any>();
    const [abn, setAbn] = useState<any>();
    const [selectedFile, setSelectedFile] = useState<File>();
    const [preview, setPreview] = useState(null);

    // for modals
    const [showAbnModal, setShowAbnModal] = useState(false);
    const [showPwdModal, setShowPwdModal] = useState(false);
    const [showNumberModal, setShowNumberModal] = useState(false)
    const [pwd, setPwd] = useState<any>();

    const fetchProfileData = () => {
        api.get("profile/", null)
            .then((res) => {
                cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                setRemoteUserData(res)
                setEmail(res?.user?.email)
                setBusinessName(res?.businessName)
                setTradingName(res?.tradingName)
                setContactName(res?.contact?.name)
                setNumber(res?.user?.mobileNumber)
                setContactNumber(res?.contact?.mobileNumber)
                emailRef.current.value = res?.user?.email
                contactRef.current.value = res?.contact?.name
                businessRef.current.value = res?.businessName
                tradingRef.current.value = res?.tradingName
                for (let i = 0; i <= res?.locations?.length; i++) {
                    if (res?.locations?.[i]?.isPrimary === true) {
                        addressRef.current.value = res?.locations?.[i]?.address
                    }
                }
            })
            .then(() => setSubmitData({}))
            .then(() => {
                props.history.push("/dashboard/settings/", { tab: "updateProfile" })
            })
    }

    const patchData = (data) => {
        api.patch("profile/", data)
            .then((res) => {
                fetchProfileData()
                setShowPwdModal(false); setPwd("");
                setPreview(null); setSelectedFile(null);
                SuccessToast("Success!", "Your profile details have been updated successfully")
            })
            .then(() => {
                if (preview) {
                    imageSubmit && patchImage(false)
                }
            })
            .catch(e => {
                fetchProfileData()
                setSubmitData({})
                setShowPwdModal(false); setPwd("");
                ValidationErrorToast(e[0], e[1])
            })
    }

    const patchImage = (onlyImage) => {
        let formData = new FormData();
        formData.append("logo", dataURLtoFile(preview, Date.now() + '.jpeg'));
        api.patch("profile/", formData)
            .then((res => {
                fetchProfileData()
                setShowPwdModal(false); setPwd("");
                setPreview(null); setSelectedFile(null);
                onlyImage && SuccessToast("Success!", "Your profile details have been updated successfully")
            }))
            .catch(e => {
                fetchProfileData()
                setSubmitData({})
                setShowPwdModal(false); setPwd("");
                ValidationErrorToast(e[0], e[1])
            })
    }

    const validate = () => {
        if (!UtilityFunctions.validateEmail(email)) {
            setErrorState((prev) => { return { ...prev, email: true } })
        }
        else if (UtilityFunctions.isEmptyOrNullOrUndefined(email)) {
            setErrorState((prev) => { return { ...prev, email: true } })
        }
        else setErrorState((prev) => { return { ...prev, email: false } })

        if (UtilityFunctions.isEmptyOrNullOrUndefined(businessName)) {
            setErrorState((prev) => { return { ...prev, business: true } })
        }
        else setErrorState((prev) => { return { ...prev, business: false } })

        if (UtilityFunctions.isEmptyOrNullOrUndefined(tradingName)) {
            setErrorState((prev) => { return { ...prev, trading: true } })
        }
        else setErrorState((prev) => { return { ...prev, trading: false } })

        if (UtilityFunctions.isEmptyOrNullOrUndefined(contactName)) {
            setErrorState((prev) => { return { ...prev, contactName: true } })
        }
        else setErrorState((prev) => { return { ...prev, contactName: false } })

    }

    const submitDataHandler = () => {
        if (preview || (
            (emailRef?.current?.value !== remoteUserData?.user?.email)
            || (number !== remoteUserData?.user?.mobileNumber)
            || (businessRef.current.value !== remoteUserData?.businessName)
            || (tradingRef.current.value !== remoteUserData?.tradingName)
            || (contactRef.current.value !== remoteUserData?.contact?.name)
            || (contactNumber !== remoteUserData?.contact?.mobileNumber))) {
            if (((emailRef?.current?.value !== remoteUserData?.user?.email) ||
                (number !== remoteUserData?.user?.mobileNumber)) && pwd === "") {
                return;
            }
            else {
                if (preview) {
                    delete submitData["logo"]
                    patchData(submitData)
                }
                else if (!preview) {
                    delete submitData["logo"]
                    patchData(submitData)
                }
            }
        }
        else if (Object.keys(submitData).length === 0 && preview) {
            patchImage(true)
        }
    }

    useEffect(() => {
        fetchProfileData()
    }, [abn])

    useEffect(() => validate(), [email, businessName, tradingName, contactName])

    useEffect(() => {
        let checker = arr => arr.every(v => v === false);
        let validationKeys = Object.values(errorState)
        if (!checker(validationKeys) && Object.keys(submitData).length > 0) {
            ValidationErrorToast("Error!", "One or more field(s) is empty or invalid")
        }
        else if (Object.keys(submitData).length > 0) {
            submitDataHandler()
        }
    }, [submitData])

    const updateHandler = () => {
        // console.log('updateHandler called')
        if ((emailRef?.current?.value !== remoteUserData?.user?.email) || (number !== remoteUserData?.user?.mobileNumber)) {
            if ((emailRef?.current?.value !== remoteUserData?.user?.email) && (number !== remoteUserData?.user?.mobileNumber)) {
                // console.log("email & number changed")
                setSubmitData((prev) => { return { ...prev, user: { email: emailRef?.current?.value, mobileNumber: number, password: pwd } } })
            }
            else if ((emailRef?.current?.value !== remoteUserData?.user?.email) && (number === remoteUserData?.user?.mobileNumber)) {
                // console.log("email changed")
                setSubmitData((prev) => { return { ...prev, user: { email: emailRef?.current?.value, password: pwd } } })
            }
            else if ((emailRef?.current?.value === remoteUserData?.user?.email) && (number !== remoteUserData?.user?.mobileNumber)) {
                // console.log("number changed")
                setSubmitData((prev) => { return { ...prev, user: { mobileNumber: number, password: pwd } } })
            }
            if (errorState.email === false && errorState.number === false) setShowPwdModal(true)
        }
        if (businessRef.current.value !== remoteUserData?.businessName) {
            setSubmitData((prev) => { return { ...prev, businessName: businessRef.current.value } })
        }
        if (tradingRef.current.value !== remoteUserData?.tradingName) {
            setSubmitData((prev) => { return { ...prev, tradingName: tradingRef.current.value } })
        }
        if ((contactRef.current.value !== remoteUserData?.contact?.name) || (contactNumber !== remoteUserData?.contact?.mobileNumber)) {
            setSubmitData((prev) => { return { ...prev, contact: { name: contactRef.current.value, mobileNumber: contactNumber } } })
        }
        if (preview) {
            setSubmitData((prev) => { return { ...prev, logo: preview } })
            setImageSubmit(true)
        }
    }

    const removeFile = () => {
        setSelectedFile(null)
        setPreview(null)
    }

    const setAddresses = () => props.history.push({
        pathname: STRING_CONSTANTS.navigationString.employer_profile_completion,
        state: { step: 3, updateAddress: true }
    })

    return remoteUserData ? <>
        <div className="tab-pane fade show active" id="panel1" role="tabpanel" aria-labelledby="panel1-tab">
            <div className="container-fluid">
                <div className='title-row'>
                    <h1>Update Profile</h1>
                </div>
                <div className="scroll-bar-dash-full margn-top38">
                    <div className="row">
                        <div className="col-12">
                            <div className="blue-shadow-box mt-0">
                                <h3>Personal Details</h3>
                                <div className="row margn-top26">
                                    <div className="col-xl-6 col-md-12 col-sm-12">
                                        <div className="profile-pic-wrap mb-4">
                                            <div className="profile-image">
                                                <img src={remoteUserData?.logo || UtilityFunctions.getImageURL() + "dashboard-profile-pic.png"} />
                                            </div>
                                            <div className="upload-image">
                                                <div className='update-image'>
                                                    {(selectedFile || preview) ?
                                                        <div className="crossed-icon">
                                                            <img alt="Selected Image" className="image-show" src={UtilityFunctions.getImageURL() + "cross-icon.svg"} onClick={(e) => removeFile()} />
                                                            <img alt="Preview" className="image-show" src={preview} />
                                                        </div> :
                                                        <span>
                                                            <ImageCrop
                                                                ratio={1 / 1}
                                                                croppedImage={(imgSrc, file) => {
                                                                    setSelectedFile(file)
                                                                    setPreview(imgSrc);
                                                                }}
                                                                size={25}
                                                            />
                                                            <label htmlFor="image_id" style={{ cursor: "pointer" }}>
                                                                {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4">
                                                                    </path>
                                                                </svg> */}
                                                                <img src={UtilityFunctions.getImageURL() + "add_img.svg"} className="plus-icon" alt="" />
                                                                <p>Update Image</p>
                                                            </label>
                                                        </span>}
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                    <div className="col-xl-6 col-md-12 col-sm-12">
                                        <div className="form-wrap2">
                                            <div className=" mb-3">
                                                <div className={`input-group input-group-lg mb-3 ${errorState.email ? "error" : focusState.email ? "input-focus-profile" : ""}`}>
                                                    <span className="input-group-text" id="basic-addon1">
                                                        {/* <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20 4.608V12.75C20 14.483 18.6435 15.8992 16.9344 15.9949L16.75 16H3.25C1.51697 16 0.100754 14.6435 0.00514483 12.9344L0 12.75V4.608L9.65199 9.66437C9.86995 9.77854 10.13 9.77854 10.348 9.66437L20 4.608ZM3.25 0H16.75C18.4347 0 19.8201 1.28191 19.9838 2.92355L10 8.15334L0.0161917 2.92355C0.173861 1.34271 1.46432 0.09545 3.06409 0.00522852L3.25 0H16.75H3.25Z" fill="white" />
                                                        </svg> */}
                                                        <img src={UtilityFunctions.getImageURL() + "mail-fill-gray.svg"} alt="" />
                                                        <img src={UtilityFunctions.getImageURL() + "mail-fill-blue.svg"} alt="" />
                                                    </span>
                                                    <input type="text"
                                                        onFocus={() => setFocusState((prev) => { return { ...prev, email: true } })}
                                                        onBlur={() => setFocusState((prev) => { return { ...prev, email: false } })}
                                                        className="form-control"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        ref={emailRef}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value)
                                                        }}
                                                        defaultValue={remoteUserData?.user?.email}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" mb-3">
                                                <div className="input-group input-group-lg mb-3 justify-content-between disabled">
                                                    <span>
                                                        <PhoneInput
                                                            disabled
                                                            onFocus={() => setFocusState((prev) => { return { ...prev, number: true } })}
                                                            onBlur={() => setFocusState((prev) => { return { ...prev, number: false } })}
                                                            country={'au'}
                                                            placeholder="000 000 000"
                                                            countryCodeEditable={false}
                                                            masks={{ au: '... ... ....', in: '...........' }}
                                                            enableAreaCodeStretch
                                                            value={number}
                                                            onChange={phone => setNumber('+' + phone)}
                                                        />
                                                    </span>
                                                    <span
                                                        className="input-group-text tex-upbut "
                                                        id="basic-addon1"
                                                        onClick={() => setShowNumberModal(true)}
                                                    >
                                                        Update
                                                    </span>
                                                </div >
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-6"> <div className="blue-shadow-box">
                            <h3>Business Details</h3>
                            <div className="row margn-top26">

                                <div className="col-xl-12 col-md-12">
                                    <div className="form-wrap2">
                                        <div className=" mb-3">
                                            <div className={`input-group input-group-lg mb-3 ${errorState.business ? "error" : focusState.business ? "input-focus-profile" : ""}`}>
                                                <span className="input-group-text" id="basic-addon1">
                                                    {/* <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.75 0C1.50736 0 0.5 1.00736 0.5 2.25V18.75C0.5 19.1642 0.835786 19.5 1.25 19.5H3.5V15.25C3.5 14.8358 3.83579 14.5 4.25 14.5H11.75C12.1642 14.5 12.5 14.8358 12.5 15.25V19.5H14.75C15.1642 19.5 15.5 19.1642 15.5 18.75V9.7493C15.5 8.50666 14.4926 7.4993 13.25 7.4993H12.5V2.25C12.5 1.00736 11.4926 0 10.25 0H2.75ZM3.5 4.5C3.5 3.94772 3.94772 3.5 4.5 3.5C5.05228 3.5 5.5 3.94772 5.5 4.5C5.5 5.05228 5.05228 5.5 4.5 5.5C3.94772 5.5 3.5 5.05228 3.5 4.5ZM4.5 10.5C5.05228 10.5 5.5 10.9477 5.5 11.5C5.5 12.0523 5.05228 12.5 4.5 12.5C3.94772 12.5 3.5 12.0523 3.5 11.5C3.5 10.9477 3.94772 10.5 4.5 10.5ZM3.5 8C3.5 7.44772 3.94772 7 4.5 7C5.05228 7 5.5 7.44772 5.5 8C5.5 8.55228 5.05228 9 4.5 9C3.94772 9 3.5 8.55228 3.5 8ZM8 3.5C8.55228 3.5 9 3.94772 9 4.5C9 5.05228 8.55228 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5ZM7 11.5C7 10.9477 7.44772 10.5 8 10.5C8.55228 10.5 9 10.9477 9 11.5C9 12.0523 8.55228 12.5 8 12.5C7.44772 12.5 7 12.0523 7 11.5ZM11.5 10.5C12.0523 10.5 12.5 10.9477 12.5 11.5C12.5 12.0523 12.0523 12.5 11.5 12.5C10.9477 12.5 10.5 12.0523 10.5 11.5C10.5 10.9477 10.9477 10.5 11.5 10.5ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8ZM11 19.5V16H8.74993V19.5H11ZM7.24993 19.5V16H5V19.5H7.24993Z" fill="white" />
                                                    </svg> */}
                                                    <img src={UtilityFunctions.getImageURL() + "building-input.svg"} alt="" />
                                                    <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                                                </span>
                                                <input type="text"
                                                    onFocus={() => setFocusState((prev) => { return { ...prev, business: true } })}
                                                    onBlur={() => setFocusState((prev) => { return { ...prev, business: false } })}
                                                    className="form-control"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    ref={businessRef}
                                                    onChange={e => {
                                                        setBusinessName(e.target.value)
                                                    }}
                                                    defaultValue={remoteUserData?.businessName}
                                                />
                                            </div >
                                        </div >

                                        <div className=" mb-3">
                                            <div className="input-group input-group-lg mb-3 disabled">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <img src={UtilityFunctions.getImageURL() + "hashtag.svg"} alt="" />
                                                    <img src={UtilityFunctions.getImageURL() + "hashtag-fill.svg"} alt="" />
                                                    {/* <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.98691 0.889534C9.0639 0.482538 8.79637 0.0901919 8.38938 0.0132045C7.98238 -0.0637829 7.59003 0.203742 7.51305 0.610738L6.49396 5.99815L1.74965 6.00037C1.33544 6.00056 0.999807 6.3365 1 6.75072C1.00019 7.16493 1.33614 7.50056 1.75035 7.50037L6.2102 7.49828L5.26445 12.498L0.74965 12.5001C0.335436 12.5003 -0.000193421 12.8363 8.36311e-08 13.2505C0.000193672 13.6647 0.336137 14.0003 0.75035 14.0001L4.98068 13.9981L4.01302 19.1137C3.93603 19.5207 4.20356 19.9131 4.61055 19.99C5.01755 20.067 5.4099 19.7995 5.48688 19.3925L6.50742 13.9974L11.9814 13.9949L11.0131 19.1137C10.9361 19.5207 11.2036 19.9131 11.6106 19.99C12.0176 20.067 12.41 19.7995 12.4869 19.3925L13.5081 13.9942L18.2503 13.9919C18.6646 13.9917 19.0002 13.6558 19 13.2416C18.9998 12.8274 18.6639 12.4918 18.2496 12.4919L13.7919 12.494L14.7376 7.4943L19.2503 7.49219C19.6646 7.49199 20.0002 7.15605 20 6.74184C19.9998 6.32762 19.6639 5.99199 19.2496 5.99219L15.0214 5.99416L15.987 0.889534C16.064 0.482538 15.7964 0.0901919 15.3894 0.0132045C14.9824 -0.0637829 14.5901 0.203742 14.5131 0.610738L13.4946 5.99488L8.0207 5.99744L8.98691 0.889534ZM7.73693 7.49757L13.2109 7.49501L12.2651 12.4947L6.79118 12.4973L7.73693 7.49757Z" fill="white" />
                                                    </svg> */}
                                                </span>
                                                <input type="text"
                                                    className="form-control disabled"
                                                    disabled aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    value={remoteUserData?.abn}
                                                    defaultValue={remoteUserData?.abn}
                                                    placeholder={"Enter ABN Number"}
                                                />
                                                <span
                                                    className="input-group-text tex-upbut"
                                                    id="basic-addon1"
                                                    onClick={() => setShowAbnModal(true)}
                                                >
                                                    Update ABN
                                                </span>
                                            </div >
                                        </div>

                                        <div className=" mb-3">
                                            <div className={`input-group input-group-lg mb-3 ${errorState.trading ? "error" : focusState.trading ? "input-focus-profile" : ""}`}>
                                                <span className="input-group-text" id="basic-addon1">
                                                    <img src={UtilityFunctions.getImageURL() + "building-input.svg"} alt="" />
                                                    <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                                                </span>
                                                <input type="text"
                                                    onFocus={() => setFocusState((prev) => { return { ...prev, trading: true } })}
                                                    onBlur={() => setFocusState((prev) => { return { ...prev, trading: false } })}
                                                    className="form-control"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    ref={tradingRef}
                                                    onChange={e => {
                                                        setTradingName(e.target.value)
                                                    }}
                                                    defaultValue={remoteUserData?.tradingName}
                                                />
                                            </div >
                                        </div >

                                        <div className="">
                                            <div className="input-group input-group-lg mb-3">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <img src={UtilityFunctions.getImageURL() + "location-icon-blank.svg"} alt="" />
                                                    <img src={UtilityFunctions.getImageURL() + "location-icon-fill.svg"} alt="" />
                                                    {/* <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.1573 14.8823L13.9703 16.0562C13.0954 16.9149 11.9603 18.0188 10.5644 19.3684C9.69218 20.2118 8.30836 20.2117 7.43624 19.3682L3.94515 15.9723C3.50638 15.5415 3.13912 15.1782 2.84328 14.8823C-0.557134 11.4819 -0.557134 5.96878 2.84328 2.56837C6.24369 -0.832036 11.7568 -0.832036 15.1573 2.56837C18.5577 5.96878 18.5577 11.4819 15.1573 14.8823ZM11.5005 9.00006C11.5005 7.61924 10.3811 6.49986 9.00026 6.49986C7.61944 6.49986 6.50006 7.61924 6.50006 9.00006C6.50006 10.3809 7.61944 11.5003 9.00026 11.5003C10.3811 11.5003 11.5005 10.3809 11.5005 9.00006Z" fill="white" />
                                                    </svg> */}
                                                </span>
                                                <input type="text"
                                                    className="form-control disabled"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    ref={addressRef}
                                                    onClick={() => setAddresses()}
                                                />
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-6">
                            <div className="blue-shadow-box">
                                <h3>Contact Details</h3>

                                <div className="row margn-top26">

                                    <div className="col-xl-12 col-md-12">
                                        <div className="form-wrap2">
                                            <div className="mb-3">
                                                <div className={`input-group input-group-lg mb-3 ${errorState.contactName ? "error" : focusState.contactName ? "input-focus-profile" : ""}`}>
                                                    <span className="input-group-text" id="basic-addon1">
                                                        {/* <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.7545 11.9997C14.9966 11.9997 16.0034 13.0065 16.0034 14.2486V15.167C16.0034 15.7404 15.8242 16.2994 15.4908 16.7659C13.9449 18.9292 11.4206 20.0008 8.00036 20.0008C4.5794 20.0008 2.05643 18.9287 0.514274 16.7643C0.182312 16.2984 0.00390625 15.7406 0.00390625 15.1685V14.2486C0.00390625 13.0065 1.01076 11.9997 2.25278 11.9997H13.7545ZM8.00036 0.00439453C10.7618 0.00439453 13.0004 2.24297 13.0004 5.00439C13.0004 7.76582 10.7618 10.0044 8.00036 10.0044C5.23894 10.0044 3.00036 7.76582 3.00036 5.00439C3.00036 2.24297 5.23894 0.00439453 8.00036 0.00439453Z" fill="white" />
                                                        </svg> */}
                                                        <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans.svg"} alt="" />
                                                        <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans-checked.svg"} alt="" />
                                                    </span>
                                                    <input type="text"
                                                        onFocus={() => setFocusState((prev) => { return { ...prev, contactName: true } })}
                                                        onBlur={() => setFocusState((prev) => { return { ...prev, contactName: false } })}
                                                        className="form-control"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        ref={contactRef}
                                                        onChange={e => {
                                                            setContactName(e.target.value)
                                                        }}
                                                        defaultValue={remoteUserData?.contact?.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`input-group mb-3 ${focusState.contactNo ? "input-focus-profile" : ""}`} >
                                                <PhoneInput
                                                    onFocus={() => setFocusState((prev) => { return { ...prev, contactNo: true } })}
                                                    onBlur={() => setFocusState((prev) => { return { ...prev, contactNo: false } })}
                                                    country={'au'}
                                                    placeholder="00 000 000"
                                                    countryCodeEditable={false}
                                                    masks={{ au: '... ... ....', in: '...........' }}
                                                    enableAreaCodeStretch
                                                    value={contactNumber}
                                                    onChange={(phone) => setContactNumber('+' + phone)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btnfgo-wrap active">
                                <button onClick={updateHandler}>
                                    Update
                                </button>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        </div>
        <UpdateAbn
            show={showAbnModal}
            handleClose={() => setShowAbnModal(false)}
            setAbn={setAbn}
            prevAbn={remoteUserData?.abn}
            abn={abn}
        />
        <UpdateEmailNumber
            show={showPwdModal}
            handleClose={() => setShowPwdModal(false)}
            fetchProfileData={fetchProfileData}
            setPwd={setPwd}
            pwd={pwd}
            updateHandler={updateHandler}
        />
        <UpdateNumber
            show={showNumberModal}
            handleClose={() => setShowNumberModal(false)}
        />
    </> : <div></div>
}

export default withRouter(UpdateProfileEmployer)