import { withRouter } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { userService } from "../../../_services";
import ToastHeader from "../../../components/toastHeader";
import parse from "html-react-parser";

const Privacy = (props: any) => {
    const [content, setContent] = useState('');
    useEffect(() => {
        userService.legalContent()
            .then((res) => {
                res.filter(i => i.contentType === "PP").map((item) => {
                    let parsedContent: any = parse(item.content);
                    return setContent(parsedContent);

                });
            })
            .catch(function (error) {
                ToastHeader(error);
            });
    }, []);

    return (
        <div className="tab-pane fade show active " id="panel5" role="tabpane" aria-labelledby="panel5-tab">
            <div className="container-fluid">
                <div className='title-row'>
                    <h1>Privacy Policy</h1>
                </div>
                <div className="blue-shadow-box">
                    <div className="legal-content legal-scroll">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Privacy);